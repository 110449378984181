type LogoProps = {
  /** A size multiplier to the logo's original size. @defaultValue "small" = 1 */
  size?: "small" | "medium" | "large" | number;
};

/**
 * Company logo of The Pique Lab.
 */
export function ThePiqueLabLogo(props: LogoProps) {
  const parsedSize = (() => {
    switch (props.size) {
      case "small":
        return 1;
      case "medium":
        return 2;
      case "large":
        return 3;
      default:
        return props.size ?? 1;
    }
  })();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={87.9 * parsedSize}
      height={43.8 * parsedSize}
      viewBox="0 0 89 44"
      fill="none"
      style={{
        paddingTop: 2.09,
        paddingBottom: 2.09,
        paddingLeft: 2.43,
        paddingRight: 2.43,
      }}
    >
      <mask
        id="mask0_401_107"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="89"
        height="44"
      >
        <path
          d="M88.3543 0.0866089H0.425201V43.9055H88.3543V0.0866089Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_401_107)">
        <mask
          id="mask1_401_107"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="89"
          height="44"
        >
          <path
            d="M88.3543 0.0866089H0.425201V43.9055H88.3543V0.0866089Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_401_107)">
          <path
            d="M0.425201 16.748L4.58268 16.2362L4.8504 18.4016C5.40945 17.3701 6.93701 15.5433 10.2126 15.1417C12.1024 14.9134 14.2835 15.3622 15.7638 16.4803C17.0158 17.4567 18.5827 19.3228 19.0551 22.622C19.378 24.9842 18.8032 27.2441 17.441 28.9921C16.2913 30.5039 14.252 31.5118 12.3307 31.748C8.98426 32.1575 7.07874 30.8819 6.29922 30.0866L7.29922 38.2283L3.14174 38.7402L0.440949 16.7559L0.425201 16.748ZM10.6929 28.0709L10.8976 28.0472C13.8032 27.6929 15.315 25.7874 14.9685 22.9213C14.6221 20.0866 12.7323 18.6693 9.76378 19.0315L9.55906 19.0551C6.62205 19.4173 5.13386 21.2756 5.48819 24.1811C5.83465 27.0157 7.72441 28.4331 10.6929 28.0709Z"
            fill="#F2303A"
          />
          <path
            d="M53.2992 24.2677L54.4724 14.7086L58.5905 15.2126L57.4567 24.4331C57.1732 26.7638 58.2362 28.1653 60.5276 28.4409L60.6929 28.4646C63.0236 28.748 64.5354 27.6299 64.8031 25.4016L65.9449 16.1102L70.063 16.6142L68.1024 32.5905L63.9449 32.0787L64.189 30.1181C63.4331 30.9527 61.7638 32.252 59.1024 31.9291C54.2047 31.3307 52.874 27.6299 53.2913 24.252"
            fill="#F2303A"
          />
          <path
            d="M71.5433 24.7716C70.5906 17.0394 76.3937 15.4331 78.8977 15.126C81.6299 14.7874 83.8347 15.378 85.4882 16.8504C87.8425 18.9606 88.3859 22.5669 88.3622 24.0709L75.8662 25.6063C76.0473 26.5118 76.5276 27.3386 77.252 27.9764C78.1654 28.685 79.3701 29.0157 80.5118 28.8425L80.7166 28.8189C83.5197 28.4724 84.1811 26.3386 84.1811 26.3386L88.2992 25.8346C88.2992 25.8346 87.5512 31.4803 81 32.2835C79.1418 32.5118 72.4646 32.2992 71.5433 24.7716ZM83.9685 21.5669C83.8189 20.622 83.3386 19.7559 82.6063 19.126C81.8425 18.4646 80.7481 18.2205 79.3937 18.3858H79.3622C78.0473 18.5512 76.9449 19.063 76.3229 19.8976C75.7323 20.6929 75.4725 21.6457 75.5197 22.5984L83.9607 21.5591L83.9685 21.5669Z"
            fill="#F2303A"
          />
          <path
            d="M25.2284 8.30708L25.4646 8.33858C27.2835 8.55905 27.9449 9.3622 27.7244 11.1181C27.4803 13.1102 26.378 13.4173 24.8268 13.2283L24.5906 13.1968C22.7323 12.9685 22.1102 12.1732 22.3307 10.3858C22.5118 8.937 23.063 8.03937 25.2205 8.30708M22.3307 15.3858L26.4803 15.8976L24.5197 31.8661L20.3701 31.3543L22.3307 15.3858Z"
            fill="#F2303A"
          />
          <path
            d="M37.3228 17.4646C36.0787 18.0236 36.811 19.8504 38.0945 19.3937C39.8189 18.7008 41.937 19.5197 42.7795 21.1732C43.063 21.7244 43.2205 22.3386 43.2283 22.9606C43.2992 24.315 45.2598 24.2913 45.3071 22.937C45.3622 18.8346 41.1417 15.8819 37.3228 17.4567"
            fill="#F2303A"
          />
          <path
            d="M53.5906 34.1811L49.1181 29.7087C50.4488 27.7953 51.2205 25.4646 51.2205 22.9449C51.2205 16.4331 45.9921 11.1417 39.5354 11.1417C33.0787 11.1417 27.8504 16.4252 27.8504 22.9449C27.8504 29.4646 33.0787 34.748 39.5354 34.748C42.0079 34.748 44.2992 33.9764 46.189 32.6535L50.6614 37.126C51.063 37.5276 51.5984 37.7323 52.126 37.7323C52.6536 37.7323 53.189 37.5276 53.5906 37.126C54.4016 36.315 54.4016 35 53.5906 34.189M32.1339 22.9527C32.1339 18.8268 35.4488 15.4803 39.5354 15.4803C43.6221 15.4803 46.937 18.8268 46.937 22.9527C46.937 27.0787 43.6299 30.4252 39.5354 30.4252C35.441 30.4252 32.1339 27.0787 32.1339 22.9527Z"
            fill="#F2303A"
          />
          <path
            d="M32.4567 1.85827L30.8819 2.04724L30.7323 0.818897L35.2599 0.259842L35.4095 1.49606L33.8268 1.68504L34.5669 7.75591L33.2047 7.92126L32.4567 1.85827Z"
            fill="#F2303A"
          />
          <path
            d="M36.7166 0.0866089H38.0945V3.01574H41.1496V0.0866089H42.5355V7.44094H41.1496V4.3307H38.0945V7.44094H36.7166V0.0866089Z"
            fill="#F2303A"
          />
          <path
            d="M44.6929 0.330704L48.3386 0.779523L48.2047 1.87401L46.7008 1.69291C46.1339 1.62204 45.7559 1.94488 45.6851 2.56692C45.5984 3.29921 46.0236 3.61417 46.4646 3.66929L47.8425 3.83464L47.7087 4.94488L46.3307 4.77952C45.7559 4.70866 45.3622 5.03936 45.2914 5.66929C45.2284 6.15747 45.4095 6.7244 46.0866 6.80314L47.5906 6.98425L47.4567 8.07874L43.811 7.62992L44.7087 0.330704H44.6929Z"
            fill="#F2303A"
          />
          <path
            d="M60.2126 35.9685L61.5512 36.1339L60.7953 42.2362L63.0945 42.5197L62.9449 43.7087L59.315 43.2677L60.2126 35.9685Z"
            fill="#F2303A"
          />
          <path
            d="M66.6299 36.5591H68.3228L70.9606 43.9134H69.5512L69.3701 43.4173C69.2677 43.1575 69.1496 42.937 68.9606 42.748C68.6063 42.4252 68.1181 42.2677 67.5197 42.252H67.4173C66.7953 42.2598 66.3228 42.4094 65.9764 42.7165C65.7559 42.9055 65.6378 43.1575 65.5354 43.3858L65.3386 43.9134H63.9528L66.6378 36.5591H66.6299ZM68.5433 41.2598L67.4724 37.9134L66.378 41.2598H68.5433Z"
            fill="#F2303A"
          />
          <path
            d="M71.1338 36.4409L73.1575 36.189C73.9842 36.0866 75.5512 36.0945 75.748 37.6929C75.9291 39.1417 74.5905 39.5118 74.5905 39.5118C74.5905 39.5118 76.5669 39.2913 76.8031 41.2126C76.9764 42.6142 75.9764 43.252 74.5748 43.4252L72.0236 43.7401L71.126 36.4409H71.1338ZM73.6614 39.0945H73.7008C74.3386 39.0079 74.6693 38.6063 74.5905 37.9842C74.5118 37.3543 74.0945 37.0394 73.4567 37.1181H73.4173C72.7716 37.2047 72.4488 37.6063 72.5276 38.2283C72.6063 38.8425 73.0236 39.1732 73.6614 39.0945ZM74.2441 42.4567H74.2992C75.0394 42.3622 75.4173 41.8898 75.3307 41.1811C75.2441 40.4646 74.7716 40.0945 74.0315 40.189H73.9764C73.2362 40.2835 72.8661 40.748 72.9527 41.4567C73.0394 42.189 73.5118 42.5512 74.2441 42.4646"
            fill="#F2303A"
          />
        </g>
      </g>
    </svg>
  );
}
